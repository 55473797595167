const regionProfiles = {
  global: {
    domainSuffix: 'manage.aws.bmw.cloud',
    awsLoginDetails: {
      iamLoginUrl: 'https://111802884793.signin.aws.amazon.com/console',
      webeamLoginUrl: 'https://login.aws.bmw.cloud/',
      cdhLoginUrl: 'https://data.bmw.cloud/',
      switchRoleUrl: 'https://signin.aws.amazon.com/',
      loginAccountId: '111802884793',
      loginRegion: 'eu-west-1',
      myBMWItUrl: 'https://bmwgroupprod.service-now.com/mybmwit?id=sc_cat_item&service_id=public_cloud',
      awsCliProfile: 'bmwsso',
      awsPartition: 'aws',
    },
    awsMasterAccountIds: {
      216268842436: 'AWS Secure Organization INT',
      708820737385: 'AWS Secure Organization PROD',
      740783461026: 'AWS Global Organization INT',
      946848987374: 'AWS Global Organization PROD',
    },
    swaggerFile: 'user-api-customer-portal-v1-swagger.json',
    currency: 'USD',
    tabPrefix: 'AWS',
    language: 'en-US',
  },
  china: {
    domainSuffix: 'manage.aws-cn.bmw.cloud',
    awsLoginDetails: {
      iamLoginUrl: 'https://265795087340.signin.amazonaws.cn/console',
      webeamLoginUrl: 'https://login.aws.bmwchina.cloud/',
      cdhLoginUrl: 'https://data.bmw.cloud/',
      switchRoleUrl: 'https://signin.amazonaws.cn/',
      loginAccountId: '265795087340',
      loginRegion: 'cn-north-1',
      myBMWItUrl: 'https://bmwgroupprod.service-now.com/sp?id=sc_cat_item&service_id=public_cloud_china',
      awsCliProfile: 'bmwssocn',
      awsPartition: 'aws-cn',
    },
    awsMasterAccountIds: {
      956774802373: 'AWS China Organization PROD',
      956879067252: 'AWS China Organization INT',
    },
    swaggerFile: 'user-api-customer-portal-v1-swagger.json',
    currency: 'CNY',
    tabPrefix: 'AWS China',
    language: 'zh-CN',
  },
};

const defaultScope = 'phone email openid aws.cognito.signin.user.admin profile';

const cognitoProfiles = {
  dev: {
    authDomain: 'idp.dev.manage.aws.bmw.cloud',
    userPoolId:
      process.env.PORTAL_CONFIG && process.env.PORTAL_CONFIG.cognitoUserPoolId
        ? process.env.PORTAL_CONFIG.cognitoUserPoolId
        : 'eu-west-1_VaZuq0Fi4',
    clientId:
      process.env.PORTAL_CONFIG && process.env.PORTAL_CONFIG.cognitoAppClientId
        ? process.env.PORTAL_CONFIG.cognitoAppClientId
        : '2tqbb3m1m15ok7ge2d332pnj88',
    scope: defaultScope,
  },
  int: {
    authDomain: 'idp.int.manage.aws.bmw.cloud',
    userPoolId:
      process.env.PORTAL_CONFIG && process.env.PORTAL_CONFIG.cognitoUserPoolId
        ? process.env.PORTAL_CONFIG.cognitoUserPoolId
        : 'eu-west-1_HMYNZpCqq',
    clientId:
      process.env.PORTAL_CONFIG && process.env.PORTAL_CONFIG.cognitoAppClientId
        ? process.env.PORTAL_CONFIG.cognitoAppClientId
        : '1ki376mcb3jelii4mmuoalig3n',
    scope: defaultScope,
  },
  test: {
    authDomain: 'idp.test.manage.aws.bmw.cloud',
    userPoolId:
      process.env.PORTAL_CONFIG && process.env.PORTAL_CONFIG.cognitoUserPoolId
        ? process.env.PORTAL_CONFIG.cognitoUserPoolId
        : 'eu-west-1_xtYhnmqkg',
    clientId:
      process.env.PORTAL_CONFIG && process.env.PORTAL_CONFIG.cognitoAppClientId
        ? process.env.PORTAL_CONFIG.cognitoAppClientId
        : '4og53qkqbpr916hb16ra8fvl77',
    scope: defaultScope,
  },
  prod: {
    authDomain: 'idp.manage.aws.bmw.cloud',
    userPoolId:
      process.env.PORTAL_CONFIG && process.env.PORTAL_CONFIG.cognitoUserPoolId
        ? process.env.PORTAL_CONFIG.cognitoUserPoolId
        : 'eu-west-1_1ixf3kxul',
    clientId:
      process.env.PORTAL_CONFIG && process.env.PORTAL_CONFIG.cognitoAppClientId
        ? process.env.PORTAL_CONFIG.cognitoAppClientId
        : 'sipan764e5booblljqj0gemqg',
    scope: defaultScope,
  },
  testChina: {
    authDomain: 'idp.test.manage.aws-cn.bmw.cloud',
    userPoolId:
      process.env.PORTAL_CONFIG && process.env.PORTAL_CONFIG.cognitoUserPoolId
        ? process.env.PORTAL_CONFIG.cognitoUserPoolId
        : 'eu-west-1_xMsFVDSE1',
    clientId:
      process.env.PORTAL_CONFIG && process.env.PORTAL_CONFIG.cognitoAppClientId
        ? process.env.PORTAL_CONFIG.cognitoAppClientId
        : '26682smdsf31g3k6msq5t2nu13',
    scope: defaultScope,
  },
  intChina: {
    authDomain: 'idp.int.manage.aws-cn.bmw.cloud',
    userPoolId:
      process.env.PORTAL_CONFIG && process.env.PORTAL_CONFIG.cognitoUserPoolId
        ? process.env.PORTAL_CONFIG.cognitoUserPoolId
        : 'ap-southeast-1_lyglNyxvq',
    clientId:
      process.env.PORTAL_CONFIG && process.env.PORTAL_CONFIG.cognitoAppClientId
        ? process.env.PORTAL_CONFIG.cognitoAppClientId
        : '2lcngomug743b3qg6b20iqlsef',
    scope: defaultScope,
  },
  prodChina: {
    authDomain: 'idp.manage.aws-cn.bmw.cloud',
    userPoolId:
      process.env.PORTAL_CONFIG && process.env.PORTAL_CONFIG.cognitoUserPoolId
        ? process.env.PORTAL_CONFIG.cognitoUserPoolId
        : 'eu-west-1_7Caukkbwl',
    clientId:
      process.env.PORTAL_CONFIG && process.env.PORTAL_CONFIG.cognitoAppClientId
        ? process.env.PORTAL_CONFIG.cognitoAppClientId
        : '1qqngjhlm3hrhsaiivtnlcefst',
    scope: defaultScope,
  },
};

const environmentProfiles = {
  'dev|aws': {
    ...cognitoProfiles.dev,
    ...regionProfiles.global,
  },
  'int|aws': {
    ...cognitoProfiles.int,
    ...regionProfiles.global,
  },
  'prod|aws': {
    backendEnv: 'prod',
    ...cognitoProfiles.prod,
    ...regionProfiles.global,
  },
  'int|aws-cn': {
    ...cognitoProfiles.intChina,
    ...regionProfiles.china,
  },
  'prod|aws-cn': {
    backendEnv: 'prod',
    ...cognitoProfiles.prodChina,
    ...regionProfiles.china,
  },
  localhost: {
    // Note: process.env.STAGE_NAME gets injected at build time
    backendEnv: process.env.STAGE_NAME,
    apiDomainPrefix: `${process.env.STAGE_NAME}.`,
    redirectUrl: 'http://localhost:8080/',
    ...cognitoProfiles.dev,
    ...regionProfiles.global,
  },
};

const hostnameRegex = /^(?:(?<subdomain>[a-z_-]+)\.)?manage\.(?<region>aws|aws-cn)\.bmw\.cloud$/;

function getEnvProfileForUrl(url) {
  const match = url.hostname.match(hostnameRegex);
  if (match) {
    const { subdomain, region } = match.groups;
    const environments = Object.keys(environmentProfiles);
    const key = `${subdomain ?? 'prod'}|${region}`;
    const envProfile =
      environments.indexOf(key) === -1 ? environmentProfiles[`dev|${region}`] : environmentProfiles[key];

    return {
      // use default values for backendEnv and apiDomainPrefix if not specified
      backendEnv: subdomain,
      apiDomainPrefix: subdomain ? subdomain + '.' : '',
      ...envProfile,
    };
  } else {
    // if on localhost
    // save stage name in session storage
    // used by microservices on localhost development
    sessionStorage.setItem('stageName', process.env.STAGE_NAME);

    return environmentProfiles.localhost;
  }
}

// check if it is on localhost
// used in microservices loading js
export const checkOnLocalhost = () => {
  const url = new URL(window.location.href);
  const match = url.hostname.match(hostnameRegex);
  return !match;
};

export function getConfigFromUrl(url) {
  if (!url) {
    url = window.location.href;
  }
  if (typeof url === 'string') {
    url = new URL(url);
  }

  const envProfile = getEnvProfileForUrl(url);
  const { apiDomainPrefix, clientId, domainSuffix, authDomain } = envProfile;

  const redirectUrl = envProfile.redirectUrl ?? `${url.protocol}//${url.host}/`;
  const swaggerUrl = `https://${apiDomainPrefix}${domainSuffix}/static/swagger/${envProfile.swaggerFile}`;

  return {
    redirectUrl,
    apiEndpoint: `https://frontend-api.${apiDomainPrefix}${domainSuffix}/v1`,
    apiUserAPIEndpoint: `https://user-api.${apiDomainPrefix}${domainSuffix}/v1`,
    authEndpoint: `https://${authDomain}/oauth2/token`,
    logoutEndpoint: `https://${authDomain}/logout?client_id=${clientId}&logout_uri=https://auth.bmwgroup.com/auth/XUI/?realm=%2Finternetb2xmfaonly#logout/`,
    swaggerUrl,
    ...envProfile,
    webEAMAuthorizeParams: {
      identity_provider: 'BMWWebEAM',
      redirect_uri: redirectUrl,
      response_type: 'CODE',
      client_id: envProfile.clientId,
      scope: envProfile.scope,
    },
  };
}

export const CONF = getConfigFromUrl();

export function getMicroServicesConfig(url) {
  if (!url) {
    url = window.location.href;
  }
  if (typeof url === 'string') {
    url = new URL(url);
  }

  const envProfile = getEnvProfileForUrl(url);
  const { apiDomainPrefix, domainSuffix } = envProfile;
  const changelogBaseUrl = `https://${apiDomainPrefix}${domainSuffix}/static/micro_service_changelogs`;
  const changelogBaseUrlMicroService = `https://${apiDomainPrefix}${domainSuffix}/micro_services`;

  // register micro-services names
  return {
    'aws-send-mails': {
      url: `${changelogBaseUrl}/_aws_send_mails_changelog.html`,
    },
    'aws-customer-portal-feature-flags-frontend': {
      url: `${changelogBaseUrlMicroService}/aws-customer-portal-feature-flags-frontend/_changelog.html`,
    },
    'aws-customer-portal-fqdn-management': {
      url: `${changelogBaseUrlMicroService}/aws-customer-portal-fqdn-management/_changelog.html`,
    },
    'aws-customer-portal-idc-management': {
      url: `${changelogBaseUrlMicroService}/aws-customer-portal-idc-management/_changelog.html`,
    },
  };
}

export const micro_services = getMicroServicesConfig();
